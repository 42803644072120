/* website: 3718-rdl-toyota
 * created at 2022-03-15 15:55 by fbbreard
 */

// Import all makes and organization styles files
@import "../templates/makes/toyota.scss";
@import "../utils/icons.scss";

@include breakpoint(mobile){
    .widget-sr {
      &.dealer__rdl-toyota {
        .LoginBanner{
          padding: 0px!important;
        }
        .LoginBanner .LoginBannerWrapper {
          height: 34px;
          max-height: 34px;
          .login-content {
            display: flex;
            padding-right: 0;
            #button-login,
            #button-register {
              font-size: 12px !important;
              display:flex;
              justify-content:center;
              align-items:center;
              .label{
                font-size: 12px !important;
              }
            }
  
            #button-login.btn-login {
              margin-left: 10px;
            }
  
            #button-register.btn-register {
              margin-left: 0;
              min-width: 110px !important;
              padding: 5px !important;
            }
          }
        }
      }
    }
  }